@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham/Gotham-Book.otf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Gotham Thin";
  src: url("../public/fonts/Gotham/Gotham-Thin.otf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Gotham Light";
  src: url("../public/fonts/Gotham/Gotham-Light.otf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Gotham Semi bold";
  src: url("../public/fonts/Gotham/Gotham-Semi-bold.otf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gotham Bold";
  src: url("../public/fonts/Gotham/Gotham-Bold.otf");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../public/fonts/Helvetica/Helvetica-Neue-LT-Common.otf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Helvetica Neue Thin";
  src: url("../public/fonts/Helvetica/Helvetica-Neue-LT-Thin.otf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Helvetica Neue Light";
  src: url("../public/fonts/Helvetica/Helvetica-Neue-LT-Light.otf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Helvetica Neue Semi bold";
  src: url("../public/fonts/Helvetica/Helvetica-Neue-LT-Semi-Bold.otf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Helvetica Neue Bold";
  src: url("../public/fonts/Helvetica/Helvetica-Neue-LT-Bold.otf");
  font-style: normal;
  font-weight: bold;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
