.wrapper-hero {
    background: #ffffff url('../../../../public/assets/landing/background-hero.webp') no-repeat center;
    background-size: cover;
}

.wrapper-info {
    background: #ffffff url('../../../../public/assets/landing/background-package.webp') no-repeat center;
    background-size: cover;

} 

@media (min-width: 1900px)  {
    .cardDetail {
        margin-block: 5em !important;
        padding: 4em;
    }
    .footer-card {
        bottom: 3em;
    }
}

@media (min-width: 1750px)  {
   .button-app{
        height: 4.5em;
        width: 15em;
   }
}